<template>
  <v-slide-group
    v-model="model"
    center-active
    active-class="activeOne"
    show-arrows
    style="border-bottom: 0px; padding: 16xpx 32px"
  >
    <template v-slot:prev>
      <v-icon>mdi-chevron-left</v-icon>
    </template>
    <template v-slot:next>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <v-slide-item v-for="situationRow in situations" :key="situationRow.name" v-slot="{ active, toggle }">
      <v-card
        :color="active ? '#ffffff ' : '#f9fafb'"
        :style="
          active ? 'border: 1px solid #e7e7fa; box-shadow: none !important;transition: 0 opacity !important;' : ''
        "
        height="190"
        width="300"
        @click="toggle"
      >
        <v-scale-transition>
          <CardByStatus :situation="situationRow" :activeOrNot="active" />
        </v-scale-transition>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>
<script>
import { mapGetters } from 'vuex';
import ThemeMixin from '@/mixins/Theme.vue';

import 'dayjs/locale/pt-br';

export default {
  name: 'Carousel',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    CardByStatus: () => import('./CardByStatus.vue'),
    CardByStatusNoData: () => import('./CardByStatus.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      model: true,
    };
  },

  computed: {
    ...mapGetters({
      situations: 'esocial/esocialMain/getSituationForCards',
    }),
  },
};
</script>
<style>
.v-slide-group__prev--disabled {
  color: #8d8d99 !important;
  background-color: #e7e7fa;
  width: 32px !important;
}
.v-slide-group__prev {
  width: 32px !important;
  min-width: 32px !important;
  border: 1px solid #e7e7fa !important;
}
.v-slide-group__next {
  width: 32px !important;
  min-width: 32px !important;
  border: 1px solid #e7e7fa !important;
  color: 'primary';
}
.v-slide-group__next--disabled {
  color: #8d8d99 !important;
  background-color: #e7e7fa;
}
.v-slide-group__prev > i:nth-child::before {
  color: #8d8d99 !important;
}
.activeOne {
  text-transform: none;
  box-shadow: none !important;
}

.v-card--link:focus {
  text-transform: none;
  box-shadow: none !important;
}

.v-card--link:focus::before {
  opacity: 0;
  text-transform: none;
  box-shadow: none !important;
}
</style>
