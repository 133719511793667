var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-slide-group",
    {
      staticStyle: { "border-bottom": "0px", padding: "16xpx 32px" },
      attrs: {
        "center-active": "",
        "active-class": "activeOne",
        "show-arrows": "",
      },
      scopedSlots: _vm._u([
        {
          key: "prev",
          fn: function () {
            return [_c("v-icon", [_vm._v("mdi-chevron-left")])]
          },
          proxy: true,
        },
        {
          key: "next",
          fn: function () {
            return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.model,
        callback: function ($$v) {
          _vm.model = $$v
        },
        expression: "model",
      },
    },
    _vm._l(_vm.situations, function (situationRow) {
      return _c("v-slide-item", {
        key: situationRow.name,
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function ({ active, toggle }) {
                return [
                  _c(
                    "v-card",
                    {
                      style: active
                        ? "border: 1px solid #e7e7fa; box-shadow: none !important;transition: 0 opacity !important;"
                        : "",
                      attrs: {
                        color: active ? "#ffffff " : "#f9fafb",
                        height: "190",
                        width: "300",
                      },
                      on: { click: toggle },
                    },
                    [
                      _c(
                        "v-scale-transition",
                        [
                          _c("CardByStatus", {
                            attrs: {
                              situation: situationRow,
                              activeOrNot: active,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }